import { FC } from 'react'
import { Album } from '@api/gql/graphql'

import { AlbumItem } from './album-item'

type Props = {
  albums?: Album[] | null;
};

export const CellAlbums: FC<Props> = (props) => {
  return (
    <div className="flex flex-col gap-y-1 justify-center">
      {props.albums?.map((album) => <AlbumItem album={album} key={album.id} />)}
    </div>
  )
}
