import { FC, ReactNode } from 'react'

import { RangeVariantGroup } from '../types'

type Props = {
  group: RangeVariantGroup
  children: ReactNode
}

export const RangeVariantGroupItem: FC<Props> = (props) => {
  return (
    <div key={props.group.year} className="mb-4 md:mb-8">
      <div className="mb-4 text-gray-400">{props.group.year}</div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {props.children}
      </div>
    </div>
  )
}
