import { FC, useMemo } from 'react'
import { OrderAddress } from '@api/gql/graphql'
import upperFirst from 'lodash/upperFirst'

type Props = {
  address?: OrderAddress | null
}

export const AddressItem: FC<Props> = (props) => {
  const values = useMemo((): { [key: string]: string } => {
    return {
      'Postal code': props.address?.postal_code ?? '-',
      Country: props.address?.country ?? '-',
      State: props.address?.state ?? '-',
      City: props.address?.city ?? '-',
      'Line 1': props.address?.line1 ?? '-',
      'Line 2': props.address?.line2 ?? '-',
      Email: props.address?.email ?? '-',
      Phone: props.address?.phone ?? '-',
    }
  }, [])

  return (
    <div className="mb-2">
      <div className="text-base font-bold">{`${upperFirst(props.address?.type ?? '')}:`}</div>
      {Object.keys(values).map((key) => (
        <div className="grid grid-cols-2 border-b py-1 last:border-0" key={key}>
          <div className="text-gray-500">
            {key}
          </div>
          <div className="break-words">
            {values[key] ?? '-'}
          </div>
        </div>
      ))}
    </div>
  )
}
