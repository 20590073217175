import { graphql } from '@api/gql/gql'

export const updateOrder = graphql(`
    mutation updateOrder($input: OrderInput!) {
        update_order(input: $input) {
            id
            moderator {
                id
                name
                role
            }
            peecho_id
            peecho_status
            moderation_status
        }
    }
`)
