import { FC } from 'react'
import fake1 from '@assets/fakes/fake-1.png'
import fake2 from '@assets/fakes/fake-2.png'
import fake3 from '@assets/fakes/fake-3.png'
import fake4 from '@assets/fakes/fake-4.png'
import fake5 from '@assets/fakes/fake-5.png'
import fake6 from '@assets/fakes/fake-6.png'
import { ASSET_URL } from '@constants/link'

type Props = {
  coverUuid?: string
}

export const Cover: FC<Props> = (props) => (
  <div className="mb-2">
    {props.coverUuid
      ? <img src={`${ASSET_URL}/${props.coverUuid}`} className="object-contain w-full aspect-square" alt="" />
      : (
        <div className="grid grid-cols-3 gap-2 w-full">
          <img src={fake1} className="w-full" alt="" />
          <img src={fake2} className="w-full" alt="" />
          <img src={fake3} className="w-full" alt="" />
          <img src={fake4} className="w-full" alt="" />
          <img src={fake5} className="w-full" alt="" />
          <img src={fake6} className="w-full" alt="" />
        </div>
      )}
  </div>
)
