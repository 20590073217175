import { FC } from 'react'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@nextui-org/button'
import { Link } from '@nextui-org/link'
import { clsx } from 'clsx'

type Props = {
  title: string
  spreadIndex: number
  isPrevDisabled: boolean
  isNextDisabled: boolean
  onNavigateToSpread: (spreadIndex: number) => void
  className?: string
}

export const SpreadNavigation: FC<Props> = (props) => {
  return (
    <div className={clsx('grid grid-cols-12 items-center text-xl font-medium', props.className)}>
      <div className="col-span-3">
        <Button
          as={Link}
          variant="light"
          isIconOnly
          className="w-full"
          isDisabled={props.isPrevDisabled}
          startContent={<FontAwesomeIcon icon={faChevronLeft} size="lg" className="text-gray-400" />}
          onClick={() => props.onNavigateToSpread(props.spreadIndex - 1)}
        />
      </div>

      <div className="col-span-6 flex justify-center px-4">
        {props.title}
      </div>

      <div className="col-span-3 flex justify-end">
        <Button
          as={Link}
          variant="light"
          isIconOnly
          className="w-full"
          isDisabled={props.isNextDisabled}
          startContent={<FontAwesomeIcon icon={faChevronRight} size="lg" className="text-gray-400" />}
          onClick={() => props.onNavigateToSpread(props.spreadIndex + 1)}
        />
      </div>
    </div>
  )
}
