import { FC } from 'react'
import { Spinner } from '@nextui-org/spinner'

type Props = {
  isLoading: boolean;
};

export const RedirectLoginStatus: FC<Props> = (props) => {
  if (!props.isLoading) {
    return <></>
  }

  return (
    <div className="flex flex-col items-center">
      <Spinner
        className="mb-4"
        size="sm"
      />

      <div className="text-xl font-bold">
        Logging into Facebook
      </div>

      <div className="text-gray-600">You will now be redirected to Facebook</div>
    </div>
  )
}
