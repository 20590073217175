export const getStripeLink = (stripeId: string) => {
  if (stripeId.startsWith('pi_')) {
    if (import.meta.env.VITE_STRIPE_ENV === 'test') {
      return `https://dashboard.stripe.com/test/payments/${stripeId}`
    }

    return `https://dashboard.stripe.com/payments/${stripeId}`
  }

  if (stripeId.startsWith('cs_')) {
    if (import.meta.env.VITE_STRIPE_ENV === 'test') {
      return `https://dashboard.stripe.com/test/no-cost-orders/${stripeId}`
    }

    return `https://dashboard.stripe.com/no-cost-orders/${stripeId}`
  }

  return '#'
}
