import { graphql } from '@api/gql/gql'

export const getOrderCost = graphql(`
    query getOrderCost($orderId: ID!) {
        order_cost(order_id: $orderId) {
            total
            subtotal
        }
    }
`)
