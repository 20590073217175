import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { toast } from '@components/toast'
import { PageName } from '@constants/analytic'
import { ERROR } from '@constants/error'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { useHeaderContext } from '@layouts/context'
import { Spinner } from '@nextui-org/spinner'
import { DateTime } from 'luxon'

import { getOrderOwnLast } from './api'

// Страница перенаправления на последний созданный заказ
export const CurrentPage = () => {
  const headerContext = useHeaderContext()

  const getOrdersOwnState = useQuery(getOrderOwnLast, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data.order_own_last?.id) {
        return
      }

      window.location.href = `/orders/${data.order_own_last?.id}`
    },
    onError: (err) => {
      if (!err.graphQLErrors.length) {
        return
      }

      const graphQLError = err.graphQLErrors[0]

      if (graphQLError.extensions.code === ERROR.NOT_FOUND) {
        const fromAt = DateTime.fromObject({
          year: DateTime.now().year,
          month: 1,
          day: 1,
          hour: 0,
          minute: 0,
        })

        const toAt = DateTime.fromObject({
          year: DateTime.now().year,
          month: 12,
          day: 31,
          hour: 23,
          minute: 59,
          second: 59,
        })

        window.location.href = `/create?range=${fromAt.toFormat('yyyy-MM-dd')},${toAt.toFormat('yyyy-MM-dd')}`
        return
      }

      toast.error(graphQLError.message)
    },
  })

  useEffect(() => {
    headerContext.setBackPath('/create')

    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.Current,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.Current,
    })

    facebookPixel.event('PageView')
  }, [])

  return (
    <div className="flex justify-center">
      {getOrdersOwnState.loading && <Spinner />}
    </div>
  )
}
