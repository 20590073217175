import { FC } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { Button } from '@nextui-org/button'
import { AlbumSpreadView } from '@share/album'

type PageParams = {
  orderId: string
  albumId: string
  spreadIndex: string
}

export const OrderSpreadPage: FC = () => {
  const navigate = useNavigate()
  const {
    orderId, albumId, spreadIndex,
  } = useParams<PageParams>()

  return (
    <div>
      <AlbumSpreadView
        orderId={orderId as string}
        albumId={albumId as string}
        spreadIndex={Number(spreadIndex) ?? 0}
      />

      <div className="fixed bottom-0 w-full flex justify-center pb-4">
        <Button
          variant="solid"
          color="primary"
          size="lg"
          className="rounded-full"
          onClick={() => {
            navigate(`/orders/${orderId}#spread-${spreadIndex}`)
          }}
        >
          Return to the book
        </Button>
      </div>
    </div>
  )
}
