import { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export const useDebouncedValue = <T>(
  defaultValue: T,
  callback: (value: T) => void,
  delay: number = 500,
): [T, (value: T) => void] => {
  const [value, setValue] = useState<T>(defaultValue)

  const setDebounced = useDebouncedCallback(callback, delay)

  useEffect(() => {
    setDebounced(value)
  }, [value])

  const set = useCallback((val: T) => {
    setValue(val)
  }, [])

  return [value, set]
}
