import { graphql } from '@api/gql/gql'

export const getModerators = graphql(`
    query getModerators {
        moderators {
            id
            name
        }
    }
`)
