import { FC } from 'react'
import { Order, OrderStatus } from '@api/gql/graphql'
import { useMutation } from '@apollo/client'
import { toast } from '@components/toast'
import { PEECHO_ORDER_LINK } from '@constants/link'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@nextui-org/button'

import { sendToPeecho } from '../api'

type Props = {
  order: Order;
};

export const CellPeecho: FC<Props> = (props) => {
  const [sendToPeechoMutation, sendToPeechoState] = useMutation(sendToPeecho, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
    fetchPolicy: 'network-only',
  })

  const isPeechoSendEnabled = !!props.order.moderator?.id
      && props.order.status === OrderStatus.Paid

  const onSend = () => {
    sendToPeechoMutation({ variables: { orderId: props.order.id } })
  }

  return (
    <div className="">
      <div className="grid grid-cols-2 border-b py-1">
        <div className="text-gray-500">Peecho id:</div>
        {props.order.peecho_id
          ? (
            <a
              href={PEECHO_ORDER_LINK + props.order.peecho_id}
              target="_blank"
              className="text-primary underline"
              rel="noreferrer"
            >
              {props.order.peecho_id ?? '-'}
            </a>
          ) : (
            <div>
              {props.order.peecho_id ?? '-'}
            </div>
          )}
      </div>

      <div className="grid grid-cols-2 py-1">
        <div className="text-gray-500">Status:</div>
        <div className="">{props.order.peecho_status ?? '-'}</div>
      </div>

      <div className="py-1">
        <Button
          size="sm"
          variant="flat"
          isDisabled={!isPeechoSendEnabled}
          isLoading={sendToPeechoState.loading}
          startContent={<FontAwesomeIcon icon={faPrint} />}
          onClick={onSend}
        >
          Send to peecho
        </Button>
      </div>

    </div>

  )
}
