export class AlertError extends Error {
  private text: string = ''

  setText(text: string) {
    this.text = text
  }

  getText() {
    return this.text
  }
}
