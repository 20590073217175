import {
  ModerationStatus, OrderStatus, PeechoStatus,
} from '@api/gql/graphql'
import upperFirst from 'lodash/upperFirst'

export const orderStatuses = [
  OrderStatus.Paid,
  OrderStatus.Cancelled,
  OrderStatus.Created,
  OrderStatus.Refunded,
]

export const orderStatusesOptions = [
  {
    id: '',
    name: '- any -',
  },
  ...orderStatuses.map((status) => ({
    id: status,
    name: upperFirst(status).replace(/_/g, ' '),
  })),
]

export const peechoStatuses = [
  PeechoStatus.Open,
  PeechoStatus.Paid,
  PeechoStatus.Moderation,
  PeechoStatus.Error,
  PeechoStatus.Processed,
  PeechoStatus.InPrintQueue,
  PeechoStatus.InProduction,
  PeechoStatus.Shipped,
  PeechoStatus.ProductionError,
  PeechoStatus.OnHold,
  PeechoStatus.Reprocessing,
  PeechoStatus.Cancelled,
  PeechoStatus.Refunded,
  PeechoStatus.PaymentError,
]

export const peechoStatusesOptions = [
  {
    id: '',
    name: '- any -',
  },
  ...peechoStatuses.map((status) => ({
    id: status,
    name: upperFirst(status).replace(/_/g, ' '),
  })),
]

export const moderationStatuses = [
  ModerationStatus.Processing,
  ModerationStatus.NeedsModeration,
  ModerationStatus.PaidInPeecho,
  ModerationStatus.SentToPeecho,
]

export const moderationStatusesOptions = [
  {
    id: '',
    name: '- any -',
  },
  ...moderationStatuses.map((status) => ({
    id: status,
    name: upperFirst(status).replace(/_/g, ' '),
  })),
]

export type OrderCommonStatus = OrderStatus | PeechoStatus

// Совместный статус статуса оплаты и производства
export const getOrderCommonStatus = (
  orderStatus?: OrderStatus | null,
  peechoStatus?: PeechoStatus | null,
): OrderCommonStatus | null | undefined => {
  if (orderStatus === OrderStatus.Paid && peechoStatus) {
    return peechoStatus
  }

  return orderStatus
}
