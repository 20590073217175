export enum SentToPeecho {
  ANY = '',
  SENT = 'sent',
  NOT_SENT = 'not_sent',
}

export const sentToPeechoValue = {
  [SentToPeecho.ANY]: null,
  [SentToPeecho.SENT]: true,
  [SentToPeecho.NOT_SENT]: false,
}

export const sentToPeechoOptions = [
  {
    name: '- any -',
    value: SentToPeecho.ANY,
  },
  {
    name: 'Sent',
    value: SentToPeecho.SENT,
  },
  {
    name: 'Not sent',
    value: SentToPeecho.NOT_SENT,
  },
]
