import { FC } from 'react'
import { toHumanReadable } from '@helpers/i18n'
import { Tab, Tabs } from '@nextui-org/tabs'
import { CreateTab } from '@pages/create-page/types'

type Props = {
  selectedTab: CreateTab
  setSelectedTab: (value: CreateTab) => void
}

export const NavigationTabs: FC<Props> = (props) => {
  return (
    <Tabs
      selectedKey={props.selectedTab}
      variant="underlined"
      aria-label="Tabs"
      size="lg"
      onSelectionChange={(value) => props.setSelectedTab(value as CreateTab)}
    >
      <Tab key={CreateTab.All} title={toHumanReadable(CreateTab.All)} />
      <Tab
        key={CreateTab.Drafts}
        title={toHumanReadable(CreateTab.Drafts)}
      />
      <Tab
        key={CreateTab.Printed}
        title={toHumanReadable(CreateTab.Printed)}
      />
    </Tabs>
  )
}
