import { FC } from 'react'
import { faRepeat } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@nextui-org/button'
import {
  Modal, ModalBody, ModalContent, ModalHeader,
} from '@nextui-org/modal'

type Props = {
  error?: string
}

export const ErrorModal: FC<Props> = (props) => {
  return (
    <Modal
      placement="center"
      size="sm"
      backdrop="blur"
      isOpen={!!props.error}
      hideCloseButton
    >
      <ModalContent className="py-10">
        {(onClose) => (
          <form onSubmit={onClose}>
            <ModalHeader className="flex flex-col text-center text-xl lg:text-2xl">
              An error has occurred
            </ModalHeader>
            <ModalBody className="items-center">
              <div className="text-center text-sm lg:text-base text-gray-500">
                {props.error}
              </div>
              <Button
                variant="bordered"
                startContent={<FontAwesomeIcon icon={faRepeat} />}
                className="w-fit"
                onClick={() => {
                  window.location.reload()
                }}
              >
                Try again
              </Button>
            </ModalBody>
          </form>
        )}
      </ModalContent>
    </Modal>
  )
}
