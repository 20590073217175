import { useMemo } from 'react'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { AlbumPage, AlbumPageType } from '@api/gql/graphql'
import { useQuery } from '@apollo/client'
import { Alert, AlertType } from '@components/alert'
import { useApolloError } from '@helpers/apollo-error'
import { PageItem } from '@pages/print-page/components'

import { getAlbum } from './api'

export const PrintBookPage = () => {
  const { albumId } = useParams<{ albumId: string }>()
  const [searchParams] = useSearchParams()

  const onlyCover = !!searchParams.get('only-cover')
  const range = searchParams.get('range')

  const pagination = useMemo((): {
    fromPage: number;
    toPage: number;
  } | null => {
    if (!range) {
      return null
    }
    const [fromStr, toStr] = range.split(',')

    return {
      fromPage: Number(fromStr),
      toPage: Number(toStr),
    }
  }, [range])

  const getAlbumState = useQuery(getAlbum, {
    fetchPolicy: 'cache-and-network',
    variables: { id: albumId as string },
  })

  const pageError = useApolloError([getAlbumState.error])

  const album = getAlbumState.data?.album

  const pages = useMemo((): AlbumPage[] => {
    return (
      album?.pages
        ?.map((page) => ({
          ...page,
          images: page.images?.filter((image) => !image.deleted_at),
        }))
        .filter((page) => !!page.images?.length)
        .filter((_, index) => {
          // Делаем срез страниц исходя из диапазона переданных страниц
          if (!pagination) {
            return true
          }

          return (
            pagination.fromPage - 1 <= index && index <= pagination.toPage - 1
          )
        })
        .filter((page) => {
          // Отображение только обложки если передана эта настройка
          if (onlyCover) {
            return page.type === AlbumPageType.FrontCover
          }

          return true
        }) ?? []
    )
  }, [album?.pages, onlyCover, pagination])

  return (
    <>
      <Alert type={AlertType.error} message={pageError} />

      {!!album && pages.map((page) => (
        <PageItem key={page.id} album={album} albumPage={page} />
      ))}
    </>
  )
}

export default PrintBookPage
