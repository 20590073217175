import { FC } from 'react'
import { SharedSelection } from '@nextui-org/react'
import { Select, SelectItem } from '@nextui-org/select'
import { Key } from '@react-types/shared'

type Props = {
  years: { year: number }[]
  selectedYear?: 'all' | Iterable<Key>
  setSelectedYear:(keys: SharedSelection) => void
}

export const YearsSelect: FC<Props> = (props) => {
  return (
    <Select
      items={props.years}
      selectedKeys={props.selectedYear}
      label="Years"
      placeholder="All"
      className="w-full md:max-w-60"
      onSelectionChange={props.setSelectedYear}
    >
      {(item) => (
        <SelectItem
          key={item.year}
          textValue={String(item.year === 0 ? 'All' : item.year)}
        >
          {item.year === 0 ? 'All' : item.year}
        </SelectItem>
      )}
    </Select>
  )
}
