import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { toast } from '@components/toast'
import { albumPagingOffset } from '@constants/album'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHeaderContext } from '@layouts/context'
import { Button } from '@nextui-org/button'
import chunk from 'lodash/chunk'
import isEqual from 'lodash/isEqual'

import { getAlbumSpread } from './api'
import { PageItem, PageLabel, SpreadNavigation } from './components'
import { WindowSizesContext } from './context'
import { usePages, useWindowSizes } from './utils'

type Props = {
  orderId: string
  albumId: string
  spreadIndex: number
}

export const AlbumSpreadView: FC<Props> = (props) => {
  const headerContext = useHeaderContext()
  const navigate = useNavigate()

  const getAlbumSpreadState = useQuery(getAlbumSpread, {
    variables: {
      albumId: props.albumId,
      spreadIndex: props.spreadIndex,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      headerContext.setTitle(`${data.album?.title} ${data.album?.subtitle}`)
      headerContext.setBackPath(`/orders/${props.orderId}#spread-${props.spreadIndex}`, true)
      document.body.scrollIntoView({ behavior: 'instant' })
    },
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const isLoading = getAlbumSpreadState.loading
  const pagesList = getAlbumSpreadState.data?.album_spread.pages_list ?? []
  const album = getAlbumSpreadState.data?.album
      || getAlbumSpreadState.previousData?.album
      || null

  const { pagesLive, visiblePagesLive } = usePages(getAlbumSpreadState.data?.album_spread.pages
      || getAlbumSpreadState.previousData?.album_spread.pages
      || [])
  const windowSizes = useWindowSizes()

  const spreads = useMemo(
    () => chunk(pagesList.map((page, index) => ({ id: page, page_number: index })), 2),
    [pagesList],
  )

  const currentSpread = useMemo(() => {
    return spreads.find((spread) => isEqual(
      spread.map((page) => page.id),
      pagesLive.map((page) => page.id),
    ))
  }, [pagesLive])

  const pagination = useMemo(
    () => currentSpread?.map((page) => page.page_number - albumPagingOffset).join('-') ?? '',
    [currentSpread],
  )
  const isPrevDisabled = props.spreadIndex - 1 < 1
  const isNextDisabled = props.spreadIndex + 1 > spreads.length - 1
  const isEmpty = !isLoading && !visiblePagesLive.length

  const navigateToSpread = (newIndex: number) => {
    navigate(`/orders/${props.orderId}/${props.albumId}/${newIndex}`)
  }

  const getPageLabel = (pageId: string) => {
    const currentPage = currentSpread?.find((p) => p.id === pageId)
    if (!currentPage) {
      return ''
    }

    return `Page ${(currentPage.page_number || 0) - albumPagingOffset}`
  }

  return (
    <WindowSizesContext.Provider value={windowSizes}>
      <div className="page">
        <SpreadNavigation
          title={`Page ${pagination}`}
          spreadIndex={props.spreadIndex}
          isPrevDisabled={isPrevDisabled || isLoading}
          isNextDisabled={isNextDisabled || isLoading}
          onNavigateToSpread={navigateToSpread}
          className="mb-10"
        />

        <div ref={windowSizes.ref}>
          {album && visiblePagesLive?.map((page) => (
            <PageItem
              key={page.id}
              album={album}
              page={page}
              spreadIndex={props.spreadIndex}
              isLeft
              isWide
              isControl
              isSpread
              pageLabel={(
                <PageLabel>
                  {getPageLabel(page.id)}
                </PageLabel>
              )}
            />
          ))}

          {isEmpty && (
            <div className="flex flex-col items-center text-gray-600 text-center">
              <div className="mb-2 text-lg">Pages deleted</div>

              <Button
                className="w-fit"
                variant="bordered"
                startContent={<FontAwesomeIcon icon={faChevronLeft} />}
                onClick={() => navigateToSpread(1)}
              >
                Go to the beginning of the album
              </Button>
            </div>
          )}
        </div>

        {!isEmpty && (
          <SpreadNavigation
            title={`Page ${pagination}`}
            spreadIndex={props.spreadIndex}
            isPrevDisabled={isPrevDisabled || isLoading}
            isNextDisabled={isNextDisabled || isLoading}
            onNavigateToSpread={navigateToSpread}
            className="mb-10"
          />
        )}
      </div>
    </WindowSizesContext.Provider>
  )
}
