import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useMutation } from '@apollo/client'
import { toast } from '@components/toast'
import { getOrderCommonStatus } from '@constants/order'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toHumanReadable } from '@helpers/i18n'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/index'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/dropdown'
import { clsx } from 'clsx'
import { DateTime } from 'luxon'

import { deleteOrder, reorder } from '../api'
import { RangeVariant, RangeVariantType } from '../types'
import { useParams } from '../utils'

import { Cover } from './cover'

type Props = {
  rangeVariant: RangeVariant;
  onSelect: () => void;
  onContinue: () => void
  isDisabled?: boolean;
};

export const RangeVariantItem: FC<Props> = (props) => {
  const { openModal } = useModal()
  const navigate = useNavigate()

  const { facebookClickIds } = useParams()

  const [reorderMutation, reorderState] = useMutation(reorder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const [deleteOrderMutation] = useMutation(deleteOrder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const isContinue = props.rangeVariant.type === RangeVariantType.CONTINUE
  const isReorder = props.rangeVariant.type === RangeVariantType.REORDER

  const onReorder = async () => {
    openModal({
      type: ModalType.CONFIRM_MODAL,
      title: 'You have already created a book in this period',
      message: 'Those photos will be included in this album',
      onConfirm: async () => {
        // Если выбранный диапазон включает текущий год, то не копируем альбомы
        // Это для того, чтобы новые фотографии включились в новый альбом
        if (DateTime.now().year === props.rangeVariant.to_at.year) {
          props.onSelect()
          return
        }

        if (!props.rangeVariant.order_id) {
          return
        }

        const reorderResp = await reorderMutation({
          variables: {
            input: {
              order_id: props.rangeVariant.order_id,
              ...facebookClickIds,
            },
          },
        })
        if (reorderResp.data?.reorder.id) {
          navigate(`/orders/${reorderResp.data?.reorder.id}`)
        }
      },
    })
  }

  const onClick = () => {
    if (props.isDisabled) {
      return
    }

    if (isContinue) {
      props.onContinue()
      return
    }

    if (isReorder) {
      onReorder()
      return
    }

    props.onSelect()
  }

  // Удаление черновика с подтверждением
  const onDeleteOrder = () => {
    openModal({
      type: ModalType.CONFIRM_MODAL,
      title: 'Delete the draft?',
      message: 'All changes will be lost',
      onCancel: () => {},
      onConfirm: async () => {
        if (!props.rangeVariant.order_id) {
          return
        }

        await deleteOrderMutation({
          variables: {
            orderId: props.rangeVariant.order_id,
          },
        })

        window.location.reload()
      },
    })
  }

  const status = toHumanReadable(getOrderCommonStatus(
    props.rangeVariant.order_status,
    props.rangeVariant.peecho_status,
  ) ?? '')

  const createdDate = useMemo(() => {
    const paidAt = props.rangeVariant?.order_paid_at || null
    if (!paidAt) {
      return null
    }

    return paidAt?.toFormat('dd.MM.yyyy')
  }, [props.rangeVariant])

  return (
    <div className={clsx(
      'bg-gray-50 transition-background hover:bg-gray-200 rounded-lg cursor-pointer flex flex-col justify-between md:h-[405px]',
      reorderState.loading && 'opacity-50',
    )}
    >
      <div className="grid grid-cols-2 pt-4 px-4" onClick={onClick}>
        <div className="font-medium text-left">
          <div>{props.rangeVariant.title}</div>
          <div>{props.rangeVariant.subtitle}</div>
        </div>
        <div className="text-right text-xs text-gray-400">
          {!!status && <div>{status}</div>}
          {!!createdDate && <div>{createdDate}</div>}
        </div>
      </div>

      <div className="h-full pt-6" onClick={onClick} />

      <div>
        <div className="px-4" onClick={onClick}>
          <Cover coverUuid={props.rangeVariant.albums_cover_uuid} />
        </div>

        <div className="grid grid-cols-2 items-center">
          <div
            className="text-sm text-gray-400 text-left pb-4 pl-4 pt-4"
            onClick={onClick}
          >
            {isReorder ? 'Reprint' : 'Edit'}
          </div>

          {isContinue ? (
            <Dropdown>
              <DropdownTrigger>
                <div className="flex justify-end pb-4 pr-4 pt-4 group cursor-pointer">
                  <FontAwesomeIcon
                    icon={faEllipsis}
                    className="text-gray-400 group-hover:text-gray-800 transition-colors"
                  />
                </div>
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                <DropdownItem
                  key="delete"
                  className="text-danger"
                  color="danger"
                  onClick={onDeleteOrder}
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  )
}
