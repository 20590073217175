import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Order } from '@api/gql/graphql'
import { useMutation } from '@apollo/client'
import { toast } from '@components/toast'
import { getOrderCommonStatus } from '@constants/order'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { toHumanReadable } from '@helpers/i18n'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/index'
import { Button } from '@nextui-org/button'
import { Spinner } from '@nextui-org/spinner'
import { payOrder, reorder } from '@pages/order-page/api'
import currency from 'currency.js'
import { DateTime } from 'luxon'

type Props = {
  order: Order;
};

export const OrderItem: FC<Props> = (props) => {
  const { openModal } = useModal()

  const [payOrderMutation, payOrderState] = useMutation(payOrder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const [reorderMutation, reorderState] = useMutation(reorder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const isReorderLoading = payOrderState.loading || reorderState.loading

  // Пересоздание книги и переход к оплате
  const onReorder = () => {
    openModal({
      type: ModalType.CONFIRM_MODAL,
      title: 'Order again?',
      message: 'After confirmation, you will be redirected to the payment page',
      onCancel: () => {},
      onConfirm: async () => {
        amplitude.event({
          type: AmplitudeEvent.OrderClickReorder,
          order_id: props.order.id,
        })

        const reorderResp = await reorderMutation({
          variables: {
            input: {
              order_id: props.order.id,
            },
          },
        })

        const orderId = reorderResp.data?.reorder.id
        if (!orderId) {
          return
        }

        const payOrderResult = await payOrderMutation({ variables: { orderId } })
        const paymentUrl = payOrderResult.data?.pay_order.payment_url
        if (paymentUrl) {
          window.location.href = paymentUrl
        }
      },
    })
  }

  const paidAt = useMemo(
    () => DateTime.fromISO(props.order.paid_at || props.order.created_at).toFormat('dd MMM yyyy'),
    [props.order],
  )

  const total = useMemo(
    () => currency((props.order.total ?? 0) / 100).format({ symbol: '$' }),
    [props.order.total],
  )

  const title = useMemo(() => {
    if (!props.order.albums?.length) {
      return ''
    }

    return props.order.albums[0].title ?? ''
  }, [props.order])

  const subtitles = useMemo(() => {
    return props.order.albums?.map((album) => album.subtitle) ?? []
  }, [props.order])

  const orderLink = `/orders/${props.order.id}`

  // Совмещение статуса и преобразование статуса в читаемый формат
  const status = toHumanReadable(getOrderCommonStatus(props.order.status, props.order.peecho_status) ?? '')

  return (
    <div className="grid grid-cols-12 md:grid-cols-6 items-center mb-4 rounded-md bg-gray-100 transition-background hover:bg-gray-200 cursor-pointer text-gray-400">
      <a
        href={orderLink}
        className="md:hidden col-span-9 flex flex-col pl-4 py-4"
      >
        <div className="mb-4 font-medium text-black">
          {title}
          {' '}
          {subtitles.join(', ')}
        </div>

        <div className="md:hidden text-sm">
          {`${total} • ${paidAt} • ID: ${props.order.id}`}
        </div>
      </a>

      <div
        className="md:hidden flex flex-col col-span-3 pr-4 py-4 h-full items-end text-right"
        onClick={onReorder}
      >
        <div className="h-6 mb-4">
          {isReorderLoading ? (
            <Spinner size="sm" />
          ) : (
            <div className="text-black text-medium">Reprint</div>
          )}
        </div>

        <div>{status}</div>
      </div>

      <Link to={orderLink} className="pl-4 py-4 hidden md:flex md:flex-col font-medium text-black">
        <div>{title}</div>

        {subtitles.map((subtitle, index) => (
          <div key={index}>{subtitle}</div>
        ))}
      </Link>

      <Link
        to={orderLink}
        className="hidden md:flex text-center h-full items-center"
      >
        {total}
      </Link>

      <Link
        to={orderLink}
        className="hidden md:flex text-center h-full items-center"
      >
        {paidAt}
      </Link>

      <Link
        to={orderLink}
        className="hidden md:flex text-center h-full items-center"
      >
        {`ID: ${props.order.id}`}
      </Link>

      <Link
        to={orderLink}
        className="hidden md:flex text-center h-full items-center"
      >
        {status}
      </Link>

      <div className="hidden md:flex justify-end pr-4">
        <Button
          variant="light"
          size="lg"
          className="font-medium"
          onClick={onReorder}
          isLoading={isReorderLoading}
        >
          Reprint
        </Button>
      </div>
    </div>
  )
}
