import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { OrderStatus } from '@api/gql/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from '@components/toast'
import { getDeprecatedDraftsDate } from '@constants/album'
import { PageName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { useHeaderContext } from '@layouts/context/header-context'
import { Button } from '@nextui-org/button'
import { AlbumsNavigation, AlbumView } from '@share/album'
import { DateTime } from 'luxon'

import { deleteOrder, getOrder } from './api'
import { Checkout, Reorder } from './components'

export const OrderPage = () => {
  const { orderId } = useParams<{ orderId: string }>()
  const headerContext = useHeaderContext()

  const [currentAlbumId, setCurrentAlbumId] = useState<string | null>(null)
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false)

  const orderState = useQuery(getOrder, {
    variables: { orderId: orderId as string },
    onCompleted: (res) => {
      const albums = res.order?.albums ?? []
      if (albums.length) {
        setCurrentAlbumId(albums[0].id)
        headerContext.setTitle(`${albums[0].title} ${albums[0].subtitle}`)
        headerContext.setBackPath('/create')
      }
    },
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
    fetchPolicy: 'cache-and-network',
  })

  const [deleteOrderMutation, deleteOrderState] = useMutation(deleteOrder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.Order,
      order_id: orderId,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.Order,
    })

    facebookPixel.event('PageView')
  }, [])

  const onChangeCurrentAlbumId = (albumId: string) => {
    amplitude.event({
      type: AmplitudeEvent.AlbumClick,
      album_id: albumId,
    })

    setCurrentAlbumId(albumId)
  }

  const order = orderState.data?.order ?? null
  const albums = order?.albums ?? []
  const isStatusCompleted = order?.status
    ? [OrderStatus.Paid, OrderStatus.Cancelled, OrderStatus.Refunded].includes(
      order.status,
    )
    : false
  const isShowNavigation = !!currentAlbumId && albums.length > 1

  // Намеренное устаревание заказа
  const isDeprecated = useMemo(
    () => DateTime.fromISO(order?.created_at).diff(getDeprecatedDraftsDate(), 'seconds').seconds < 0,
    [order?.created_at],
  )

  // Заказ завершен
  if (isStatusCompleted) {
    return (
      <div className="page">
        <Reorder order={order} />
      </div>
    )
  }

  // Черновик устарел
  if (order && albums.length && order.status === OrderStatus.Created && isDeprecated) {
    const fromAt = DateTime.fromISO(albums[0].from_at)
    const toAt = DateTime.fromISO(albums[0].to_at)

    const onCreate = async () => {
      await deleteOrderMutation({ variables: { orderId: order.id } })
      setIsRedirecting(true)
      window.location.href = `/create?range=${fromAt.toFormat('yyyy-MM-dd')},${toAt.toFormat('yyyy-MM-dd')}`
    }

    return (
      <div className="page text-center">
        <h1 className="text-2xl font-medium mb-2">
          {`Draft №${order.id}`}
        </h1>

        <div className="text-black mb-4">
          A technical issue occurred. Your album wasn’t saved, but we will create a new one for you
        </div>

        <Button
          isLoading={deleteOrderState.loading || isRedirecting}
          variant="solid"
          color="primary"
          onClick={onCreate}
        >
          Create album now
        </Button>
      </div>
    )
  }

  // Редактор
  return (
    <div className="page">
      {isShowNavigation && (
        <div className="mb-3">
          <AlbumsNavigation
            albums={albums}
            currentAlbumId={currentAlbumId}
            onChangeCurrentAlbumId={onChangeCurrentAlbumId}
          />
        </div>
      )}

      {!!currentAlbumId && order && (
        <AlbumView albumId={currentAlbumId} key={currentAlbumId} editable>
          {(count) => <Checkout order={order} pagesCount={count} />}
        </AlbumView>
      )}
    </div>
  )
}

export default OrderPage
