import { FC } from 'react'
import { Order } from '@api/gql/graphql'
import { Button } from '@nextui-org/button'

type Props = {
  order?: Order | null
  onEdit: () => void
}

export const CellCover: FC<Props> = (props) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="text-lg">
        <span className="text-gray-400">ID: </span>
        <span className="font-bold">{props.order?.id}</span>
      </div>
      <Button
        type="button"
        variant="bordered"
        color="primary"
        onClick={props.onEdit}
      >
        Edit
      </Button>
    </div>
  )
}
