import { useNavigate } from 'react-router-dom'
import { GetAvailableAlbumsQuery } from '@api/gql/graphql'
import { useMutation } from '@apollo/client'
import { toast } from '@components/toast'
import { coverTypes, MAX_PHOTOS_PER_ALBUM, MIN_PHOTOS_PER_ALBUM } from '@constants/album'
import { DateTime } from 'luxon'

import { createAlbums, reorder } from '../api'
import { RangeVariant } from '../types'

import { AlbumsRangeFinder } from './albums-range-finder'
import { useHelpers } from './use-helpers'
import { useParams } from './use-params'

type Props = {
  data?: GetAvailableAlbumsQuery
}

export const useCreateAlbums = (props: Props) => {
  const navigate = useNavigate()

  const { formatSubtitle, onConfirm, handleError } = useHelpers()
  const { facebookClickIds, coverType, initialRange } = useParams()

  const [createAlbumsMutation, createAlbumsState] = useMutation(createAlbums, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })
  const [reorderMutation] = useMutation(reorder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  // Создание альбома из варианта диапазона
  const createByRangeVariant = async (rangeVariant: RangeVariant) => {
    if (!props.data?.albums_available) {
      return
    }

    await createAlbumsMutation({
      variables: {
        input: {
          subtitle: `${formatSubtitle(rangeVariant.from_at.year, rangeVariant.to_at.year)}`,
          min_photos_per_album: MIN_PHOTOS_PER_ALBUM,
          max_photos_per_album: MAX_PHOTOS_PER_ALBUM,
          from_at: rangeVariant.from_at.setZone('UTC'),
          to_at: rangeVariant.to_at.setZone('UTC'),
          cover_type: coverTypes.includes(coverType) ? coverType : undefined,
          ...facebookClickIds,
        },
      },
      onError: () => {},
    })
  }

  // Создание альбома из заданного диапазона
  const createByInitialRange = async (data: GetAvailableAlbumsQuery) => {
    try {
      const albumsRangeFinder = new AlbumsRangeFinder({
        albumsRanges: data?.albums_ranges ?? [],
        albumsAvailable: data?.albums_available.items ?? [],
        total: data?.albums_available.total ?? 0,
        initialRange,
        confirm: onConfirm,
        navigate: (path) => navigate(path),
        reorder: (orderId) => new Promise((resolve) => {
          reorderMutation({
            variables: {
              input: {
                order_id: orderId,
                ...facebookClickIds,
              },
            },
          }).then((res) => {
            if (!res.data?.reorder.id) {
              return
            }

            resolve(res.data?.reorder.id)
          })
        }),
      })

      const findRangeResult = await albumsRangeFinder.findRange()
      if (!findRangeResult) {
        return
      }

      await createAlbumsMutation({
        variables: {
          input: {
            subtitle: `${formatSubtitle(findRangeResult.fromYear, findRangeResult.toYear)}`,
            min_photos_per_album: MIN_PHOTOS_PER_ALBUM,
            max_photos_per_album: MAX_PHOTOS_PER_ALBUM,
            cover_type: coverTypes.includes(coverType) ? coverType : undefined,
            from_at: DateTime.fromObject({
              year: findRangeResult.fromYear,
              month: 1,
              day: 1,
              hour: 0,
              minute: 0,
              second: 0,
            }).setZone('UTC'),
            to_at: DateTime.fromObject({
              year: findRangeResult.toYear,
              month: 12,
              day: 31,
              hour: 23,
              minute: 59,
              second: 59,
            }).setZone('UTC'),
            ...facebookClickIds,
          },
        },
      })
    } catch (e) {
      handleError(e)
    }
  }

  return {
    createByRangeVariant,
    createByInitialRange,
    createAlbumsState,
  }
}
