import bookWithHeartBlue from '@assets/book-with-heart-blue.svg'

export const AlbumIntro = () => {
  return (
    <div className="flex h-full flex-col items-center pt-[20%]">
      <div className="flex flex-col justify-center items-center">
        <img src={bookWithHeartBlue} className="w-[70%] mb-6" alt="" />
        <div className="text-[2.4em] font-medium text-primary">EasyPhotoBook</div>
      </div>
    </div>
  )
}
