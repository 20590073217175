import { graphql } from '@api/gql/gql'

export const getTasks = graphql(`
    query getTasks {
        tasks {
            job_id
            type
            status
            steps
        }
    }
`)
