import {
  FC, useEffect, useMemo,
} from 'react'
import { Order } from '@api/gql/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from '@components/toast'
import { defaultPageFormat } from '@constants/album'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { Button } from '@nextui-org/button'
import currency from 'currency.js'

import { getOrderCost, payOrder } from '../api'

type Props = {
  order: Order
  pagesCount: number
  disabled?: boolean
}

export const Checkout: FC<Props> = (props) => {
  const [payOrderMutation, payOrderState] = useMutation(payOrder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })
  const getOrderCostState = useQuery(getOrderCost, {
    variables: { orderId: props.order.id },
    fetchPolicy: 'network-only',
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const total = useMemo(
    () => {
      const prevOrderCost = getOrderCostState.previousData?.order_cost.total
      const nextOrderCost = getOrderCostState.data?.order_cost.total

      return nextOrderCost ?? prevOrderCost ?? 0
    },
    [getOrderCostState.data?.order_cost.total],
  )

  const subtotal = useMemo(
    () => {
      const prevOrderCost = getOrderCostState.previousData?.order_cost.subtotal
      const nextOrderCost = getOrderCostState.data?.order_cost.subtotal

      return nextOrderCost ?? prevOrderCost ?? 0
    },
    [getOrderCostState.data?.order_cost.subtotal],
  )

  const onPurchaseOrder = () => {
    amplitude.event({
      type: AmplitudeEvent.OrderClickPurchase,
      order_id: props.order.id,
    })

    ga.event({
      category: GaCategory.Order,
      action: GaAction.OrderClickPay,
      value: total,
    })

    payOrderMutation({
      variables: { orderId: props.order.id },
    }).then((res) => {
      const paymentUrl = res.data?.pay_order.payment_url
      if (!paymentUrl) {
        return
      }

      facebookPixel.event('AddToCart', {
        currency: 'USD',
        value: res.data?.pay_order.id ?? 0,
        contents: [
          {
            id: defaultPageFormat,
            quantity: 1,
          },
        ],
        content_type: 'product_group',
        event_id: res.data?.pay_order.id ?? '',
      })

      window.location.href = paymentUrl
    })
  }

  const totalFormatted = useMemo(
    () => currency((total ?? 0) / 100).format({ symbol: '$' }),
    [total],
  )

  const subtotalFormatted = useMemo(
    () => currency((subtotal ?? 0) / 100).format({ symbol: '$' }),
    [subtotal],
  )

  useEffect(() => {
    getOrderCostState.refetch({ orderId: props.order.id })
  }, [props.pagesCount])

  return (
    <div className="fixed bottom-0 py-4 flex flex-col items-center w-full left-0 px-2 bg-gradient-to-t from-white via-white to-transparent bg-opacity-0">
      <div className="flex gap-4 mb-2 font-medium">
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faTruck} className="text-primary" />
          Free delivery
        </div>
      </div>

      <Button
        className="rounded-full py-7 px-10 text-base"
        color="primary"
        variant="solid"
        isDisabled={props.disabled}
        isLoading={payOrderState.loading}
        onClick={onPurchaseOrder}
      >
        {`Order book for ${totalFormatted} `}
        <span className="line-through opacity-40">
          {subtotalFormatted}
        </span>
      </Button>
    </div>
  )
}
