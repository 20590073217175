import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CoverType } from '@api/gql/graphql'
import { defaultCoverType } from '@constants/album'
import { Range } from '@pages/create-page/types'
import cookie from 'js-cookie'
import { DateTime } from 'luxon'

type Result = {
  coverType: CoverType
  initialRange: Range | null
  facebookClickIds: { fbp?: string, fbc?: string }
}

export const useParams = (): Result => {
  const [searchParams] = useSearchParams()

  // Этот диапазон можно установить в get параметрах
  const initialRange = useMemo((): Range | null => {
    const range = searchParams.get('range')

    if (!range) {
      return null
    }

    if (range === 'today') {
      return {
        fromAt: DateTime.fromObject({
          year: 2004,
          month: 1,
          day: 4,
          hour: 0,
          minute: 0,
        }),
        toAt: DateTime.now(),
      }
    }

    const [fromStr, toStr] = range.split(',')

    return {
      fromAt: DateTime.fromFormat(fromStr, 'yyyy-MM-dd'),
      toAt: DateTime.fromFormat(toStr, 'yyyy-MM-dd'),
    }
  }, [])

  // Тип обложки можно установить в get параметрах
  const coverType = (searchParams.get('cover_type') || defaultCoverType) as CoverType

  const facebookClickIds = {
    fbp: cookie.get('_fbp') || undefined,
    fbc: cookie.get('_fbc') || undefined,
  }

  return {
    initialRange,
    coverType,
    facebookClickIds,
  }
}
