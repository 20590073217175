import {
  useEffect, useMemo, useRef,
} from 'react'
import { useNavigate } from 'react-router'
import {
  TaskStatus,
  TaskType,
} from '@api/gql/graphql'
import { dropTask, subscriptionOnTask } from '@api/schemas'
import { getProfile } from '@api/schemas/get-profie'
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client'
import { toast } from '@components/toast'
import { PageName } from '@constants/analytic'
import { AuthorizationError } from '@constants/authorization-error'
import { ERROR } from '@constants/error'
import {
  amplitude,
  AmplitudeEvent,
  facebookPixel,
  ga,
  GaAction,
  GaCategory,
  useApolloError,
} from '@helpers/index'
import { tokenStore } from '@helpers/token-store'
import { useHeaderContext } from '@layouts/context'
import { ErrorModal } from '@pages/create-page/components/error-modal'

import { YearsSelect } from './components/years-select'
import { useHelpers } from './utils/use-helpers'
import {
  getAvailableAlbums,
  getOrderOwnLast,
  getTasks,
  searchImages,
} from './api'
import {
  CreateAlbumsStatus,
  NavigationTabs,
  RangeVariantGroupItem,
  RangeVariantItem,
  RedirectLoginStatus,
  SearchImagesStatus,
} from './components'
import {
  useCoupon,
  useCreateAlbums,
  useList,
  useParams,
} from './utils'

export const CreatePage = () => {
  const navigate = useNavigate()
  const headerContext = useHeaderContext()

  // Заданный диапазон
  const { initialRange } = useParams()
  const { handleError, isRedirectLogin } = useHelpers()
  useCoupon()

  // Автоматическое создание альбома по заданному диапазону
  const isAutoCreate = !!initialRange

  const [getProfileQuery] = useLazyQuery(getProfile)
  const [getOrderOwnLastQuery, getOrderOwnLastState] = useLazyQuery(getOrderOwnLast)
  const [getAvailableAlbumsQuery, getAvailableAlbumsState] = useLazyQuery(getAvailableAlbums, {
    fetchPolicy: 'cache-and-network',
  })
  const [searchImagesMutation, searchImagesState] = useMutation(searchImages, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })
  const [getTasksQuery, getTasksState] = useLazyQuery(getTasks, {
    fetchPolicy: 'network-only',
  })

  const { createByInitialRange, createByRangeVariant, createAlbumsState } = useCreateAlbums({
    data: getAvailableAlbumsState.data,
  })

  const [dropTaskMutation] = useMutation(dropTask, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  // Прослушивание событий поиска фотографий
  const subscriptionOnSearchImagesState = useSubscription(subscriptionOnTask, {
    variables: { taskType: TaskType.SearchImages, input: {} },
    onData: async (data) => {
      const task = data.data.data?.subscribe_on_task
      if (!task) {
        return
      }

      // После завершения поиска
      if (task.status === TaskStatus.Completed) {
        // Удаляем задачу
        await dropTaskMutation({ variables: { taskType: TaskType.SearchImages, input: {} } })

        // Запускаем запрос доступных альбомов
        const availableAlbumsResp = await getAvailableAlbumsQuery()
        if (!availableAlbumsResp.data) {
          return
        }

        // Если задан диапазон сразу создаем альбом
        if (isAutoCreate) {
          await createByInitialRange(availableAlbumsResp.data)
        }
      }
    },
  })

  // Прослушивание событий создания альбома
  const subscriptionOnCreateAlbumsState = useSubscription(subscriptionOnTask, {
    variables: { taskType: TaskType.CreateAlbums, input: {} },
    onData: async (data) => {
      const task = data.data.data?.subscribe_on_task
      if (!task) {
        return
      }

      // После создания альбомов
      if (task.status === TaskStatus.Completed) {
        // Удаляем задачу
        await dropTaskMutation({ variables: { taskType: TaskType.CreateAlbums, input: {} } })

        // Открываем последний созданный заказ
        const resp = await getOrderOwnLastQuery()
        const orderId = resp.data?.order_own_last?.id
        if (orderId) {
          amplitude.event({
            type: AmplitudeEvent.CreateDone,
            order_id: orderId,
            auto_create: isAutoCreate,
          })

          navigate(`/orders/${orderId}`)
        }
      }
    },
  })

  // Состояние модального окна создания альбома
  const isCreateAlbumsOpen = useMemo(() => {
    if (createAlbumsState.loading) {
      return true
    }

    const task = subscriptionOnCreateAlbumsState.data?.subscribe_on_task
    return !!task?.status && task?.status !== TaskStatus.Completed
  }, [createAlbumsState.loading, subscriptionOnCreateAlbumsState.data])

  const {
    rangeVariantGroups,
    setSelectedTab,
    setSelectedYear,
    rangeVariantYears,
    selectedTab,
    selectedYear,
  } = useList({ data: getAvailableAlbumsState.data })

  // Получение профиля. Выбрасывает исключение если пользователь не авторизован
  const fetchProfile = async () => {
    const profileState = await getProfileQuery()
    const err = profileState.error?.graphQLErrors
    if (err?.length && err[0].extensions?.code === ERROR.UNAUTHORIZED) {
      throw new AuthorizationError(err[0].message)
    }
  }

  // Запуск процесса поиска
  const startSearch = async () => {
    try {
      await fetchProfile()

      amplitude.event({
        type: AmplitudeEvent.CreateStarted,
        auto_create: isAutoCreate,
      })

      const tasksResp = await getTasksQuery()
      const isCreateAlbumsCreating = tasksResp.data?.tasks?.find(
        (task) => task.type === TaskType.CreateAlbums
             && [TaskStatus.InProgress, TaskStatus.InQueue].includes(task.status),
      )

      if (!isCreateAlbumsCreating) {
        await searchImagesMutation()
      }
    } catch (e) {
      handleError(e)
    }
  }

  // Чтобы в dev режиме поиск не выполнялся дважды
  const isRun = useRef<boolean>(false)

  useEffect(() => {
    if (isRun.current) {
      return
    }

    isRun.current = true
    startSearch().then(() => {})
  }, [])

  useEffect(() => {
    headerContext.setBackPath('/')
    headerContext.setTitle('Create a book')

    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.Create,
      auto_create: isAutoCreate,
      authorized: !!tokenStore.get(),
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.Create,
    })

    facebookPixel.event('PageView')
  }, [])

  const pageError = useApolloError([
    createAlbumsState.error,
    getAvailableAlbumsState.error,
    getOrderOwnLastState.error,
    searchImagesState.error,
    getTasksState.error,
  ])

  return (
    <div className="flex justify-center flex-col items-center page">
      <div className="w-full md:w-[900px] md:max-w-full">
        <div className="mb-12 text-3xl font-medium text-center hidden md:block">
          Create a book
        </div>

        <div className="flex flex-wrap justify-center md:justify-between w-full items-center mb-8 gap-y-4 md:gap-y-0">
          <NavigationTabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />

          <YearsSelect
            years={rangeVariantYears}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
          />
        </div>

        <SearchImagesStatus
          isLoading={searchImagesState.loading || subscriptionOnSearchImagesState.loading}
          task={subscriptionOnSearchImagesState.data?.subscribe_on_task}
        />

        <CreateAlbumsStatus
          isOpen={isCreateAlbumsOpen}
          task={subscriptionOnCreateAlbumsState.data?.subscribe_on_task}
        />

        <RedirectLoginStatus isLoading={isRedirectLogin} />

        {!isAutoCreate && rangeVariantGroups.map((group) => (
          <RangeVariantGroupItem group={group} key={group.year}>
            {group.items.map((rangeVariant) => (
              <RangeVariantItem
                key={rangeVariant.title + rangeVariant.subtitle}
                rangeVariant={rangeVariant}
                onSelect={() => createByRangeVariant(rangeVariant)}
                onContinue={() => navigate(`/orders/${rangeVariant.order_id}`)}
              />
            ))}
          </RangeVariantGroupItem>
        ))}

        <ErrorModal error={pageError} />
      </div>
    </div>
  )
}

export default CreatePage
