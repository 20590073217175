import { graphql } from '@api/gql/gql'

export const getAvailableAlbums = graphql(`
    query getAvailableAlbums {
        albums_available {
            total
            items {
                year
                count
            }
        }
        albums_ranges {
            id
            title
            subtitle
            from_at
            to_at
            order_id
            order_status
            peecho_status
            order_created_at
            order_paid_at
            album_cover_uuid
        }
    }
`)
