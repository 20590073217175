import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { apolloClient } from '@api/index'
import { ApolloProvider } from '@apollo/client'
import { ToastPlacement } from '@components/toast'
import { amplitude } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga } from '@helpers/ga'
import { sentry } from '@helpers/sentry'
import { HeaderContextProvider } from '@layouts/context'
import { ModalContextProvider } from '@modals/index'
import { NextUIProvider } from '@nextui-org/system'
import { CookieAlert } from '@share/cookie-alert'

import { AppRoutes } from './routes'

import './styles/index.css'

sentry.init()
amplitude.init()
facebookPixel.init()
ga.init()

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <NextUIProvider>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <ModalContextProvider>
            <HeaderContextProvider>
              <AppRoutes />
              <ToastPlacement />
              <CookieAlert />
            </HeaderContextProvider>
          </ModalContextProvider>
        </BrowserRouter>
      </ApolloProvider>
    </NextUIProvider>
  </StrictMode>,
)
