import { AlbumPagePadding, initialAlbumPagePadding } from '@constants/album'

export const coverPagePadding: AlbumPagePadding = {
  ...initialAlbumPagePadding,
  left: 50,
  right: 23,
  top: 23,
  bottom: 23,
}

export const defaultPagePadding: AlbumPagePadding = {
  ...initialAlbumPagePadding,
  left: 23,
  right: 23,
  top: 23,
  bottom: 23,
}
