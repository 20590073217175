import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { Order } from '@api/gql/graphql'
import { useMutation } from '@apollo/client'
import { toast } from '@components/toast'
import { getOrderCommonStatus } from '@constants/order'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { toHumanReadable } from '@helpers/i18n'
import { Button } from '@nextui-org/button'
import currency from 'currency.js'
import { DateTime } from 'luxon'

import { reorder } from '../api'

type Props = {
  order?: Order | null;
};

export const Reorder: FC<Props> = (props) => {
  const navigate = useNavigate()

  const [reorderMutation, reorderState] = useMutation(reorder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const total = useMemo(
    () => currency((props.order?.total ?? 0) / 100).format({ symbol: '$' }),
    [props.order?.total],
  )

  const onReorder = async () => {
    if (!props.order?.id) {
      return
    }

    amplitude.event({
      type: AmplitudeEvent.OrderClickReorder,
      order_id: props.order?.id,
    })

    const reorderResp = await reorderMutation({
      variables: {
        input: {
          order_id: props.order?.id,
        },
      },
    })
    if (reorderResp.data?.reorder.id) {
      navigate(`/orders/${reorderResp.data?.reorder.id}`)
    }
  }

  const paidAt = useMemo(
    () => DateTime.fromISO(
      props.order?.paid_at || props.order?.created_at,
    ).toFormat('dd MMM yyyy'),
    [props.order],
  )

  const status = toHumanReadable(
    getOrderCommonStatus(props.order?.status, props.order?.peecho_status) ?? '',
  )

  return (
    <div className="flex flex-col items-center">
      <div className="text-3xl font-medium text-center mb-2">
        {`Order ${props.order?.id}`}
      </div>

      <div className="mb-4">
        <div className="text-lg text-center mb-1">
          The status of your order:
          {' '}
          <b>{status}</b>
        </div>

        <div className="text-center mb-1">
          {`Date of payment: ${paidAt}`}
        </div>

        <div className="text-center">
          {`Total price: ${total}`}
        </div>
      </div>

      <Button
        variant="solid"
        color="primary"
        isLoading={reorderState.loading}
        onClick={onReorder}
      >
        Reprint
      </Button>
    </div>
  )
}
