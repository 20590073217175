import { useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import image from '@assets/book-with-heart.svg'
import { defaultPageFormat } from '@constants/album'
import { PageName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { Spinner } from '@nextui-org/spinner'

import { getOrderStripeSession } from './api'

export const PaymentSuccessPage = () => {
  const [searchParams] = useSearchParams()

  const getOrderStripeSessionState = useQuery(getOrderStripeSession, {
    fetchPolicy: 'network-only',
    variables: {
      sessionId: searchParams.get('session_id') ?? '',
    },
    onCompleted: (res) => {
      ga.event({
        category: GaCategory.Order,
        action: GaAction.OrderSuccessPay,
        value: res.order_stripe_session.order_total || 0,
      })

      facebookPixel.event('Purchase', {
        currency: 'USD',
        value: res.order_stripe_session.order_total || 0,
        contents: [
          {
            id: defaultPageFormat,
            quantity: 1,
          },
        ],
        content_type: 'product_group',
        event_id: res.order_stripe_session.order_id ?? '',
      })
    },
  })

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.PaymentSuccess,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.PaymentSuccess,
    })

    facebookPixel.event('PageView')
  }, [])

  const data = getOrderStripeSessionState.data?.order_stripe_session

  return (
    <div className="flex flex-col items-center justify-center text-white text-center pt-10 lg:pt-40 page">
      <img src={image} alt="" className="w-[150px] lg:w-auto mb-10" />

      <h1 className="text-xl lg:text-5xl font-bold mb-2 lg:mb-5">Thank you!</h1>

      {!getOrderStripeSessionState.loading ? (
        <>
          <p className="text-base lg:text-2xl mb-3 font-bold">
            Your order
            {' #'}
            {data?.order_id}
            {' '}
            has been placed!
          </p>
          <p className="text-sm lg:text-base mb-3 lg:max-w-[600px]">
            We have sent you an email to
            {' '}
            <b>{data?.email}</b>
            {' '}
            with the order
            confirmation and receipt. If the email did not arrive within 5
            minutes, please check your spam folder to ensure that the email was
            sent there.
          </p>
          <p className="text-sm lg:text-base">
            You can find order details and track delivery progress
            {' '}
            <Link to="/orders" className="underline">
              here
            </Link>
            .
          </p>
        </>
      ) : (
        <Spinner color="white" />
      )}
    </div>
  )
}

export default PaymentSuccessPage
