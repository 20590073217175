import { FC, useMemo } from 'react'
import { Order, OrderAddressType, OrderStatus } from '@api/gql/graphql'
import { getAmplitudeLinkToUser } from '@constants/link'
import { faInfoCircle, faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getStripeLink } from '@helpers/stripe-link'
import { Link } from '@nextui-org/link'
import { Tooltip } from '@nextui-org/tooltip'
import currency from 'currency.js'
import { DateTime } from 'luxon'

import { AddressItem } from './address-item'

type Props = {
  order: Order;
};

export const CellOrder: FC<Props> = (props) => {
  const dateAt = useMemo(
    () => DateTime.fromISO(props.order.paid_at || props.order.created_at).toFormat('dd MMM yyyy, hh:mm'),
    [props.order.created_at],
  )

  const shippingAddress = useMemo(
    () => props.order.addresses?.find((address) => address.type === OrderAddressType.Shipping),
    [props.order.addresses],
  )

  const billingAddress = useMemo(
    () => props.order.addresses?.find((address) => address.type === OrderAddressType.Billing),
    [props.order.addresses],
  )

  const addressShort = useMemo(() => {
    if (!shippingAddress) {
      return '-'
    }

    return [
      shippingAddress.country,
      shippingAddress.state,
      shippingAddress.city,
    ].filter((address) => !!address).join(', ')
  }, [shippingAddress])

  const total = useMemo(
    () => currency((props.order.total ?? 0) / 100).format({ symbol: '$' }),
    [props.order.total],
  )

  const reprintLabel = props.order.parent_id ? '(Reprinted)' : ''

  return (
    <div className="">
      <div className="grid grid-cols-2 border-b py-1">
        <div className="text-gray-500">Date:</div>
        <div title="if the order is paid, the payment date is displayed, and if not, the date of creation of the order">
          {dateAt}
        </div>
      </div>

      <div className="grid grid-cols-2 border-b py-1">
        <div className="text-gray-500">Status:</div>
        {props.order.status === OrderStatus.Paid ? (
          <Link
            href={getStripeLink(props.order.stripe_id ?? '')}
            target="_blank"
            className="flex items-center gap-x-1 text-black underline text-sm"
          >
            {props.order.status?.toUpperCase() ?? '-'}
            <FontAwesomeIcon
              icon={faLink}
              className="text-gray-400"
              size="sm"
            />
          </Link>
        ) : (
          '-'
        )}
      </div>

      <div className="grid grid-cols-2 border-b py-1">
        <div className="text-gray-500">Track:</div>
        {props.order.track_code ? (
          <Link
            href={props.order.track_url ?? ''}
            target="_blank"
            className="flex items-center gap-x-1 text-black underline text-sm"
          >
            {props.order.track_code ?? '-'}
            <FontAwesomeIcon
              icon={faLink}
              className="text-gray-400"
              size="sm"
            />
          </Link>
        ) : (
          '-'
        )}
      </div>

      <div className="grid grid-cols-2 border-b py-1">
        <div className="text-gray-500">Total:</div>
        <div className="">
          {total}
          {' '}
          {!!reprintLabel && <span className="text-green-700">{reprintLabel}</span>}
        </div>
      </div>

      <div className="grid grid-cols-2 border-b py-1">
        <div className="text-gray-500">Customer:</div>
        <div className="">
          {props.order.user?.name ?? '-'}
          {' '}
          <a
            className="underline text-primary"
            target="_blank"
            href={getAmplitudeLinkToUser(props.order.user?.id ?? '')}
            rel="noreferrer"
          >
            (amp)
          </a>
        </div>
      </div>

      <div className="grid grid-cols-2 py-1">
        <div className="text-gray-500">Addresses:</div>
        {shippingAddress ? (
          <div>
            <Tooltip
              delay={0}
              content={(
                <div className="w-[400px]">
                  <AddressItem address={shippingAddress} />
                  <AddressItem address={billingAddress} />
                </div>
                          )}
            >
              <div className="flex gap-x-2 items-center">
                {addressShort}
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-gray-400"
                  size="sm"
                />
              </div>
            </Tooltip>
          </div>
        ) : (
          <div className="">-</div>
        )}
      </div>
    </div>
  )
}
