import { AlbumAvailable, AlbumRange, OrderStatus } from '@api/gql/graphql'
import { DECADE_LIST, HALF_DECADE_LIST, MIN_PHOTOS_PER_ALBUM } from '@constants/album'
import { AlertError } from '@constants/alert-error'
import { DateTime } from 'luxon'

import {
  Range, RangeVariant, RangeVariantOrder, RangeVariantType, RangeYears,
} from '../types'

type Params = {
  albumsAvailable: AlbumAvailable[];
  albumsRanges: AlbumRange[];
  total: number;
  initialRange?: Range | null;
  confirm: (title: string, description: string) => Promise<null>;
  navigate: (path: string) => void
  reorder: (orderId: string) => Promise<string>
};

type FindRangeResult = {
  fromYear: number
  toYear: number
}

// Класс нужен для нахождения диапазона альбома
export class AlbumsRangeFinder {
  // Количество фотографий по годам
  private albumsAvailable: AlbumAvailable[] = []

  // Существующие альбомы
  private albumsRanges: AlbumRange[] = []

  // Общее количество фотографий
  private total: number = 0

  // Изначально заданный диапазон
  private initialRange: Range | null = null

  private confirm: (title: string, description: string) => Promise<null>

  private reorder: (orderId: string) => Promise<string>

  private navigate: (path: string) => void

  constructor(params: Params) {
    this.albumsAvailable = params.albumsAvailable
    this.albumsRanges = params.albumsRanges
    this.total = params.total
    this.initialRange = params.initialRange ?? null
    this.confirm = params.confirm
    this.reorder = params.reorder
    this.navigate = params.navigate
  }

  // Список всех возможных диапазонов
  getRangeVariants(): RangeVariant[] {
    let rangeVariants: RangeVariant[] = []

    rangeVariants = [
      ...rangeVariants,
      ...this.getYearRangeVariants(),
    ]

    const halfDecade = this.findHalfDecade()
    if (halfDecade) {
      if (halfDecade.fromYear !== halfDecade.toYear) {
        const fromAt = DateTime.fromObject({ year: halfDecade.fromYear, month: 1, day: 1 })
        const toAt = DateTime.fromObject({
          year: halfDecade.toYear, month: 12, day: 31, hour: 23, minute: 59, second: 59,
        })

        rangeVariants = [
          ...rangeVariants,
          {
            title: 'Highlights',
            subtitle: `${halfDecade.fromYear} - ${halfDecade.toYear}`,
            from_at: fromAt,
            to_at: toAt,
            count: this.getHalfDecadeTotal(),
            ...this.getOrderFromRange(fromAt.year, toAt.year),
          },
        ]
      }
    }

    const decade = this.findDecade()
    if (decade) {
      if (decade.fromYear !== decade.toYear && this.getHalfDecadeTotal() < this.getDecadeTotal()) {
        const fromAt = DateTime.fromObject({ year: decade.fromYear, month: 1, day: 1 })
        const toAt = DateTime.fromObject({
          year: decade.toYear, month: 12, day: 31, hour: 23, minute: 59, second: 59,
        })

        rangeVariants = [
          ...rangeVariants,
          {
            title: 'Highlights',
            subtitle: `${decade.fromYear} - ${decade.toYear}`,
            from_at: fromAt,
            to_at: toAt,
            count: 0,
            ...this.getOrderFromRange(fromAt.year, toAt.year),
          },
        ]
      }
    }

    // Добавляем недостающие заказы которые не попали в варианты
    for (const key in this.albumsRanges) {
      const albumRange = this.albumsRanges[key]

      const fromAt = DateTime.fromISO(albumRange.from_at)
      const toAt = DateTime.fromISO(albumRange.to_at)

      const notAdded = !rangeVariants.find(
        (rangeVariant) => rangeVariant.from_at.year === fromAt.year
            && rangeVariant.to_at.year === toAt.year,
      )

      if (notAdded) {
        rangeVariants = [
          ...rangeVariants,
          {
            title: albumRange.title as string,
            subtitle: albumRange.subtitle as string,
            from_at: fromAt,
            to_at: toAt,
            count: 0,
            ...this.getOrderFromRange(fromAt.year, toAt.year),
          },
        ]
      }
    }

    return rangeVariants
  }

  // Поиск ближайшего подходящего диапазона
  async findRange(): Promise<FindRangeResult | null> {
    // Если за все время не набирается минимально необходимого количества то тут ничего не поделаешь
    if (this.total < MIN_PHOTOS_PER_ALBUM) {
      this.throwNotEnoughPhotosError()
    }

    if (this.initialRange) {
      const { fromAt, toAt } = this.initialRange

      // Пробуем найти в этом году фотографии
      const isSameYear = fromAt.year === toAt.year
      if (isSameYear) {
        const currentYearTotal = this.getYearTotal(fromAt.year)
        const lastYearTotal = this.getYearTotal(fromAt.year - 1)

        // В этом году нет фотографий
        if (currentYearTotal < MIN_PHOTOS_PER_ALBUM) {
          await this.confirm(
            `Oops… Not enough photos for Highlights ${fromAt.year}.`,
            'We’ll create a photo book for a different time period.',
          )

          // С годом ранее достаточно фотографий
          if (lastYearTotal >= MIN_PHOTOS_PER_ALBUM) {
            return this.orderRouter(fromAt.year - 1, fromAt.year - 1)
          }
        }

        // В этом году достаточно фотографий
        if (currentYearTotal > MIN_PHOTOS_PER_ALBUM) {
          return this.orderRouter(fromAt.year, toAt.year)
        }
      }

      // Пробуем найти подходящий альбом за кастомный промежуток
      const customResult = this.findCustomRange(fromAt.year, toAt.year)
      if (customResult) {
        return this.orderRouter(customResult.fromYear, customResult.toYear)
      }

      // Пробуем найти подходящий альбом за полудекаду
      const halfDecadeResult = this.findHalfDecade()
      if (halfDecadeResult) {
        return this.orderRouter(halfDecadeResult.fromYear, halfDecadeResult.toYear)
      }

      // Пробуем найти подходящий альбом за декаду
      const decadeResult = this.findDecade()
      if (decadeResult) {
        return this.orderRouter(decadeResult.fromYear, decadeResult.toYear)
      }

      return null
    }

    // Пробуем найти подходящий альбом за каждый год декады (годовая серия)
    const everyYearResult = this.findEveryYear()
    if (everyYearResult) {
      return this.orderRouter(everyYearResult.fromYear, everyYearResult.toYear)
    }

    // Пробуем найти подходящий альбом за полудекаду
    const halfDecadeResult = this.findHalfDecade()
    if (halfDecadeResult) {
      return this.orderRouter(halfDecadeResult.fromYear, halfDecadeResult.toYear)
    }

    // Пробуем найти подходящий альбом за декаду
    const decadeResult = this.findDecade()
    if (decadeResult) {
      return this.orderRouter(decadeResult.fromYear, decadeResult.toYear)
    }

    return null
  }

  private throwNotEnoughPhotosError(): void {
    const err = new AlertError('It is impossible to create an album')
    err.setText('Not enough photos')
    throw err
  }

  // Доступные варианты годов декады
  private getYearRangeVariants() : RangeVariant[] {
    return this.albumsAvailable
      .filter((item) => DECADE_LIST.includes(item.year) && item.count >= MIN_PHOTOS_PER_ALBUM)
      .map((item): RangeVariant => {
        const fromAt = DateTime.fromObject({ year: item.year, month: 1, day: 1 })
        const toAt = DateTime.fromObject({
          year: item.year,
          month: 12,
          day: 31,
          hour: 23,
          minute: 59,
          second: 59,
        })

        return {
          title: 'Highlights',
          subtitle: `${item.year}`,
          from_at: fromAt,
          to_at: toAt,
          count: item.count,
          ...this.getOrderFromRange(fromAt.year, toAt.year),
        }
      })
      .sort((a, b) => b.from_at.year - a.from_at.year)
  }

  /**
   * Маршрутизатор заказа
   *
   * Проверяет есть ли в этом диапазоне заказ. Если есть, то принимает меры:
   *
   * 1. Если есть заказ, который редактируется, то направляем туда
   * 2. Если есть уже готовый заказ, то создаем из него новый заказ и направляем туда
   */
  private async orderRouter(fromYear: number, toYear: number): Promise<FindRangeResult | null> {
    const rangeVariantOrder = this.getOrderFromRange(fromYear, toYear)

    // Перенаправляем клиента на редактирование заказа
    if (rangeVariantOrder.type === RangeVariantType.CONTINUE) {
      this.navigate(this.getOrderPath(rangeVariantOrder.order_id as string))

      return null
    }

    // Перенаправляем клиента на новый заказ
    if (rangeVariantOrder.type === RangeVariantType.REORDER && rangeVariantOrder.order_id) {
      // Если выбранный диапазон включает текущий год, то не копируем альбомы
      // Это для того, чтобы новые фотографии включились в новый альбом
      if (DateTime.now().year === toYear) {
        await this.confirm(
          'You have already created a book in this period',
          'Those photos will be included in this album',
        )

        return {
          fromYear,
          toYear,
        }
      }

      // Для остальных случаев копируем альбомы со всеми изменения
      const newOrderId = await this.reorder(rangeVariantOrder.order_id)
      this.navigate(this.getOrderPath(newOrderId))

      return null
    }

    // Альбом еще не создавался. Отдаем диапазон на создание
    return {
      fromYear,
      toYear,
    }
  }

  // Находит уже существующий заказ по заданному диапазону
  private getOrderFromRange(fromYear: number, toYear: number): RangeVariantOrder {
    for (const key in this.albumsRanges) {
      const orderId = this.albumsRanges[key].order_id
      const orderStatus = this.albumsRanges[key].order_status
      const peechoStatus = this.albumsRanges[key].peecho_status
      const orderCreatedAt = this.albumsRanges[key].order_created_at
      const orderPaidAt = this.albumsRanges[key].order_paid_at
      const albumFromAt = DateTime.fromISO(this.albumsRanges[key].from_at)
      const albumToAt = DateTime.fromISO(this.albumsRanges[key].to_at)

      if (!orderId) {
        continue
      }

      const isRangeEqual = albumFromAt.year === fromYear && albumToAt.year === toYear

      // Направляем клиента чтобы он продолжил редактировать заказ
      if (isRangeEqual && orderStatus === OrderStatus.Created) {
        return {
          type: RangeVariantType.CONTINUE,
          order_id: orderId,
          order_status: orderStatus,
          peecho_status: peechoStatus,
          order_created_at: orderCreatedAt ? DateTime.fromISO(orderCreatedAt) : undefined,
          order_paid_at: orderPaidAt ? DateTime.fromISO(orderPaidAt) : undefined,
          albums_cover_uuid: this.albumsRanges[key].album_cover_uuid ?? undefined,
        }
      }

      // Создаем новый заказ из старого
      if (isRangeEqual && orderStatus === OrderStatus.Paid) {
        return {
          type: RangeVariantType.REORDER,
          order_id: orderId,
          order_status: orderStatus,
          peecho_status: peechoStatus,
          order_created_at: orderCreatedAt ? DateTime.fromISO(orderCreatedAt) : undefined,
          order_paid_at: orderPaidAt ? DateTime.fromISO(orderPaidAt) : undefined,
          albums_cover_uuid: this.albumsRanges[key].album_cover_uuid ?? undefined,
        }
      }
    }

    return { type: RangeVariantType.DEFAULT }
  }

  // Перебор каждого года декады чтобы найти минимально подходящий альбом
  private findEveryYear(): RangeYears | null {
    const decadeYearList = this.albumsAvailable
      .filter((item) => DECADE_LIST.includes(item.year))
    for (const key in decadeYearList) {
      if (decadeYearList[key].count >= MIN_PHOTOS_PER_ALBUM) {
        return { fromYear: decadeYearList[key].year, toYear: decadeYearList[key].year }
      }
    }

    return null
  }

  // Поиск подходящего альбома за кастомный период времени
  private findCustomRange(fromYear: number, toYear: number): RangeYears | null {
    const counts = this.albumsAvailable
      .filter((item) => item.year >= fromYear && item.year <= toYear)
      .sort((a, b) => a.year - b.year)

    const total = counts.reduce((prev, item) => prev + item.count, 0)

    if (total >= MIN_PHOTOS_PER_ALBUM) {
      return { fromYear, toYear }
    }

    return null
  }

  // Поиск подходящего альбома за полудекаду
  private findHalfDecade(): RangeYears | null {
    const counts = this.getHalfDecadeCounts()
    const total = this.getHalfDecadeTotal()

    if (total >= MIN_PHOTOS_PER_ALBUM) {
      return { fromYear: counts[0].year, toYear: counts[counts.length - 1].year }
    }

    return null
  }

  // Поиск подходящего альбома за декаду
  private findDecade(): RangeYears | null {
    const counts = this.getDecadeCounts()
    const decadeTotal = this.getDecadeTotal()

    if (decadeTotal >= MIN_PHOTOS_PER_ALBUM) {
      return { fromYear: counts[0].year, toYear: counts[counts.length - 1].year }
    }

    return null
  }

  // Количество фотографий за декаду
  private getDecadeCounts(): AlbumAvailable[] {
    return this.albumsAvailable
      .filter((item) => DECADE_LIST.includes(item.year))
      .sort((a, b) => a.year - b.year)
  }

  // Количество фотографий за полудекаду
  private getHalfDecadeCounts(): AlbumAvailable[] {
    return this.albumsAvailable
      .filter((item) => HALF_DECADE_LIST.includes(item.year))
      .sort((a, b) => a.year - b.year)
  }

  // Общее количество фотографий за декаду
  private getDecadeTotal(): number {
    return this.getDecadeCounts().reduce((total, item) => total + item.count, 0)
  }

  // Общее количество фотографий за полудекаду
  private getHalfDecadeTotal(): number {
    return this.getHalfDecadeCounts().reduce((total, item) => total + item.count, 0)
  }

  // Общее количество фотографий за текущий год
  private getYearTotal(year: number): number {
    return this.albumsAvailable
      .filter((item) => item.year === year)
      .reduce((total, item) => total + item.count, 0)
  }

  private getOrderPath(orderId: string): string {
    return `/orders/${orderId}`
  }
}
