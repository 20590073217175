import { useMemo } from 'react'
import { useParams } from 'react-router'
import { PageFormat } from '@api/gql/graphql'
import { useQuery } from '@apollo/client'
import { SvgHeart } from '@components/icons'
import { parsePageFormat } from '@constants/album'
import { clsx } from 'clsx'

import { getAlbum } from './api'

export const PrintSpinePage = () => {
  const { albumId } = useParams<{ albumId: string }>()

  const getAlbumState = useQuery(getAlbum, {
    fetchPolicy: 'cache-and-network',
    variables: { id: albumId as string },
  })

  const album = getAlbumState.data?.album
  const pageFormat = album?.page_format || PageFormat.Format_210x210

  const windowSizes = useMemo(() => parsePageFormat(pageFormat), [pageFormat])

  return (
    <div
      className={clsx(
        'grid grid-cols-3 h-full text-[1em] py-4',
        import.meta.env.DEV && 'border border-orange-500',
      )}
      style={{
        writingMode: 'vertical-rl',
        height: `${windowSizes.height}px`,
      }}
    >
      <div className="flex flex-col justify-center items-start leading-none text-[1em]">
        {album?.creator_name}
      </div>

      <div className="flex justify-center items-center leading-none text-[1.8em]">
        {album?.title}
      </div>

      <div className="text-center flex justify-end items-center leading-none gap-x-3 pb-2">
        <div className="text-[1.4em]">{album?.subtitle}</div>
        <SvgHeart className="w-[0.7em] h-[0.7em] text-red-600 mt-1" />
        <div className="text-[0.7em] text-gray-500" style={{ writingMode: 'horizontal-tb' }}>
          easy
          <br />
          photo
          <br />
          book
          <br />
          .me
        </div>
      </div>
    </div>
  )
}

export default PrintSpinePage
