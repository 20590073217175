import { Column } from '@constants/column'

export enum ColumnKey {
  COVER = 'cover',
  ALBUM = 'album',
  ORDER = 'order',
  MODERATION = 'moderation',
  PEECHO = 'peecho',
}

export const columns: Column[] = [
  {
    key: ColumnKey.COVER,
    label: 'Cover',
    width: 120,
  },
  {
    key: ColumnKey.ORDER,
    label: 'Order',
    width: 420,
  },
  {
    key: ColumnKey.ALBUM,
    label: 'Album',
    width: 350,
  },
  {
    key: ColumnKey.MODERATION,
    label: 'Moderation',
    width: 260,
  },
  {
    key: ColumnKey.PEECHO,
    label: 'Peecho',
    width: 250,
  },
]
