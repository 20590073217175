import { useEffect } from 'react'
import { PageName } from '@constants/analytic'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'

export const PaymentFailedPage = () => {
  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.PaymentFailed,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.PaymentFailed,
    })

    facebookPixel.event('PageView')
  }, [])

  return (
    <div className="flex flex-col items-center justify-center text-white text-center pt-40">
      <div className="bg-white rounded-full aspect-square h-20  mb-8">
        <FontAwesomeIcon icon={faXmarkCircle} className="text-red-600" size="5x" />
      </div>

      <h1 className="text-5xl font-bold mb-5">Payment failed</h1>
      <p>
        Support:
        {' '}
        <a href="mailto:support@easyphotobook.me" className="underline">
          support@easyphotobook.me
        </a>
      </p>
    </div>
  )
}

export default PaymentFailedPage
