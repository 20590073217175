import { graphql } from '@api/gql/gql'

export const getOrders = graphql(`
    query getOrdersOwn($pagination: PaginationInput!) {
        orders_own(pagination: $pagination) {
            items {
                id
                uuid
                created_at
                status
                total
                peecho_status
                
                albums {
                    id
                    title
                    subtitle
                    from_at
                    to_at
                }
            }
            page_info {
                page
                limit
                total
            }
        }
    }
`)
