import { FC, useMemo } from 'react'
import { PageInfo } from '@api/gql/graphql'
import { Pagination as PaginationNext } from '@nextui-org/pagination'

type Props = {
  pageInfo: PageInfo | undefined
  onPage: (value: number) => void
}

export const Pagination: FC<Props> = (props) => {
  const currentPage = useMemo(() => (props.pageInfo?.page || 0) + 1, [props.pageInfo?.page])
  const currentTotal = useMemo(
    () => {
      const total = props.pageInfo?.total
      if (!total) {
        return 0
      }

      return Math.ceil((total || 0) / (props.pageInfo?.limit || 0))
    },
    [props.pageInfo],
  )

  return !!currentTotal && (
  <PaginationNext
    total={currentTotal}
    page={currentPage}
    showControls
    isCompact
    disableAnimation
    onChange={props.onPage}
  />
  )
}
