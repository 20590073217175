import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  GetAvailableAlbumsQuery, OrderStatus,
} from '@api/gql/graphql'
import { Selection } from '@nextui-org/react'

import { CreateTab, RangeVariant, RangeVariantGroup } from '../types'

import { AlbumsRangeFinder } from './albums-range-finder'

type Props = {
  data?: GetAvailableAlbumsQuery
}

export const useList = (props: Props) => {
  const navigate = useNavigate()

  const [selectedYear, setSelectedYear] = useState<Selection>(new Set([]))
  const [selectedTab, setSelectedTab] = useState<CreateTab>(CreateTab.All)

  // Варианты диапазонов
  const rangeVariants = useMemo((): RangeVariant[] => {
    if (!props.data?.albums_available.items) {
      return []
    }

    const albumsRangeFinder = new AlbumsRangeFinder({
      albumsRanges: props.data.albums_ranges ?? [],
      albumsAvailable: props.data.albums_available.items ?? [],
      total: props.data.albums_available.total ?? 0,
      confirm: () => new Promise((resolve) => {
        resolve(null)
      }),
      navigate: (path) => navigate(path),
      reorder: () => new Promise((resolve) => {
        resolve('1')
      }),
    })

    return albumsRangeFinder.getRangeVariants()
  }, [props])

  // Группировка вариантов по годам
  const rangeVariantMap = useMemo((): Map<number, RangeVariant[]> => {
    const map = new Map<number, RangeVariant[]>()

    for (const key in rangeVariants) {
      const year = rangeVariants[key].to_at.year
      map.set(year, [...(map.get(year) ?? []), rangeVariants[key]])
    }

    return map
  }, [rangeVariants])

  // Список годов вариантов
  const rangeVariantYears = useMemo((): { year: number }[] => {
    let years: number[] = [0]
    for (const [year] of rangeVariantMap) {
      years = [...years, year]
    }

    return years.map((year) => ({ year }))
  }, [rangeVariants])

  // Фильтрация по году
  const filterByYear = (groups: RangeVariantGroup[]): RangeVariantGroup[] => {
    const selectedYearNumber = Array.from(selectedYear).length
      ? Number(Array.from(selectedYear)[0])
      : 0

    if (selectedYearNumber === 0) {
      return groups
    }

    return groups.filter((group) => selectedYearNumber === group.year)
  }

  // Фильтрация по вкладке
  const filterByTab = (groups: RangeVariantGroup[]): RangeVariantGroup[] => {
    if (selectedTab === CreateTab.All) {
      return groups
    }

    return groups
      .map((group) => {
        if (selectedTab === CreateTab.Drafts) {
          return {
            ...group,
            items: group.items.filter(
              (item) => item?.order_status === OrderStatus.Created,
            ),
          }
        }

        if (selectedTab === CreateTab.Printed) {
          return {
            ...group,
            items: group.items.filter(
              (item) => (item.order_status ? [
                OrderStatus.Paid,
                OrderStatus.Refunded,
                OrderStatus.Cancelled,
              ].includes(item.order_status)
                : false),
            ),
          }
        }

        return group
      })
      .filter((group) => !!group.items.length)
  }

  // Преобразование в группу с элементами
  const rangeVariantGroups = useMemo((): RangeVariantGroup[] => {
    let groups: RangeVariantGroup[] = []
    for (const [year, items] of rangeVariantMap) {
      groups = [
        ...groups,
        {
          year,
          items,
        },
      ]
    }

    return filterByTab(filterByYear(groups))
  }, [rangeVariants, selectedYear, selectedTab])

  return {
    rangeVariantGroups,
    rangeVariantYears,
    selectedYear,
    selectedTab,
    setSelectedYear,
    setSelectedTab,
  }
}
