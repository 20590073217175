import { useCallback, useState } from 'react'
import { PaginationInput } from '@api/gql/graphql'

export const usePagination = (defaultValue?: PaginationInput) => {
  const [paginationInput, setPaginationInput] = useState<PaginationInput>({
    limit: 5,
    page: 0,
    ...defaultValue,
  })

  const onPage = useCallback((page: number) => {
    setPaginationInput((s) => ({ ...s, page: page - 1 }))
  }, [])

  const onLimit = useCallback((limit: number) => {
    setPaginationInput((s) => ({ ...s, limit }))
  }, [])

  return {
    paginationInput,
    onPage,
    onLimit,
  }
}
