import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { toast } from '@components/toast'

import { applyCoupon } from '../api'

export const useCoupon = () => {
  const [searchParams] = useSearchParams()
  const [applyCouponMutation] = useMutation(applyCoupon, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  useEffect(() => {
    const coupon = searchParams.get('coupon')
    if (!coupon) {
      return
    }

    applyCouponMutation({ variables: { coupon } })
  }, [])
}
