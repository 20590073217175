import { graphql } from '@api/gql/gql'

export const sendToPeecho = graphql(`
    mutation sendToPeecho($orderId: ID!) {
        send_to_peecho(order_id: $orderId) {
            id
            moderator {
                id
                name
                role
            }
            peecho_id
            peecho_status
            moderation_status
        }
    }
`)
