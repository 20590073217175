import { OrderStatus, PeechoStatus } from '@api/gql/graphql'
import { DateTime } from 'luxon'

export type Range = {
  fromAt: DateTime;
  toAt: DateTime;
};

export type CreatingStatus = {
  title: string
  loading?: boolean
}

export type RangeVariantGroup = {
  year: number
  items: RangeVariant[]
}

export type RangeVariant = {
  title: string
  subtitle: string
  from_at: DateTime
  to_at: DateTime
  count: number
  type: RangeVariantType
  order_id?: string
  order_status?: OrderStatus | null
  peecho_status?: PeechoStatus | null
  order_created_at?: DateTime
  order_paid_at?: DateTime
  albums_cover_uuid?: string
}

export type RangeVariantOrder = {
  type: RangeVariantType,
  order_id?: string
  order_status?: OrderStatus | null
  peecho_status?: PeechoStatus | null
  order_created_at?: DateTime
  order_paid_at?: DateTime
  albums_cover_uuid?: string
}

export enum RangeVariantType {
  DEFAULT = 'default',
  REORDER = 'reorder',
  CONTINUE = 'continue',
}

export type RangeYears = {
  fromYear: number
  toYear: number
}

export enum CreateTab {
  All = 'all',
  Drafts = 'drafts',
  Printed = 'printed',
}
