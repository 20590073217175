import { FC } from 'react'
import { ModerationStatus, Order } from '@api/gql/graphql'
import { getProfile } from '@api/schemas/get-profie'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from '@components/toast'
import { faCaretDown, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@nextui-org/button'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/dropdown'

import { getModerators, updateOrder } from '../api'

type Props = {
  order: Order;
};

export const CellModeration: FC<Props> = (props) => {
  const getProfileState = useQuery(getProfile)
  const getModeratorsState = useQuery(getModerators)

  const moderators = getModeratorsState.data?.moderators ?? []
  const profile = getProfileState.data?.profile

  const [updateOrderMutation, updateOrderState] = useMutation(updateOrder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const onChangeModerator = (userId: string) => {
    updateOrderMutation({
      variables: {
        input: {
          id: props.order.id,
          moderator_id: userId,
          moderation_status: ModerationStatus.Processing,
        },
      },
    })
  }

  const onStartModeration = () => {
    updateOrderMutation({
      variables: {
        input: {
          id: props.order.id,
          moderator_id: profile?.id,
          moderation_status: ModerationStatus.Processing,
        },
      },
    })
  }

  return (
    <div className="flex flex-col gap-y-2">
      <div className="grid grid-cols-2 py-1">
        <div className="text-gray-500">Status:</div>
        <div className="">{props.order.moderation_status}</div>
      </div>

      <div>
        {props.order.moderator?.id ? (
          <Dropdown>
            <DropdownTrigger>
              <Button
                variant="flat"
                size="sm"
                endContent={<FontAwesomeIcon icon={faCaretDown} />}
              >
                {props.order.moderator?.name ?? 'Select moderator'}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              selectionMode="single"
              selectedKeys={props.order.moderator.id ? [String(props.order.moderator.id)] : []}
              aria-label="menu"
              onSelectionChange={(keys) => {
                if (Array.from(keys).length) {
                  onChangeModerator(Array.from(keys)[0] as string)
                }
              }}
            >
              {moderators.map((moderator) => (
                <DropdownItem key={moderator.id}>
                  {moderator.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        ) : (
          <Button
            size="sm"
            variant="flat"
            startContent={<FontAwesomeIcon icon={faUserCheck} />}
            onClick={onStartModeration}
            isLoading={updateOrderState.loading}
          >
            Moderate
          </Button>
        ) }
      </div>
    </div>
  )
}
