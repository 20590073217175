import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { parseDate } from '@internationalized/date'
import { Button } from '@nextui-org/button'
import { Calendar } from '@nextui-org/calendar'
import { Input, InputProps } from '@nextui-org/input'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@nextui-org/popover'
import { DateValue } from '@nextui-org/react'
import { useMask } from '@react-input/mask'
import { DateTime } from 'luxon'

export const InputDate: FC<InputProps> = (props) => {
  const ref = useMask({
    mask: '__.__.____',
    replacement: { _: /\d/ },
    showMask: true,
  })

  const [dateValue, setDateValue] = useState<string>(props.value
    ? DateTime
      .fromFormat(props.value, 'yyyy-MM-dd')
      .toFormat('dd.MM.yyyy') : '')
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const datetime = useMemo(() => DateTime.fromFormat(dateValue, 'dd.MM.yyyy'), [dateValue])
  const calendarDatetime = useMemo(
    () => (datetime.isValid ? parseDate(datetime.toFormat('yyyy-MM-dd')) as unknown as DateValue : null),
    [datetime],
  )

  const onChangeCalendar = useCallback((value: DateValue) => {
    setIsOpen(false)
    setDateValue(DateTime
      .fromFormat(value.toString(), 'yyyy-MM-dd')
      .toFormat('dd.MM.yyyy'))
  }, [])

  useEffect(() => {
    if (!props.onValueChange) {
      return
    }

    props.onValueChange(datetime.isValid ? datetime.toFormat('yyyy-MM-dd') : '')
  }, [datetime])

  return (
    <div className="relative">
      <Input
        {...props}
        ref={ref}
        onValueChange={setDateValue}
        value={dateValue}
        endContent={(
          <Popover
            placement="bottom"
            isOpen={isOpen}
            classNames={{
              content: 'p-0',
            }}
            onOpenChange={setIsOpen}
          >
            <PopoverTrigger>
              <Button
                variant="light"
                size={props.size}
                isIconOnly
                startContent={(
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="text-gray-400"
                    width={18}
                    height={18}
                  />
                )}
              />
            </PopoverTrigger>
            <PopoverContent>
              <Calendar
                lang="ru-RU"
                value={calendarDatetime}
                onChange={onChangeCalendar}
              />
            </PopoverContent>
          </Popover>
        )}
      />
    </div>
  )
}
