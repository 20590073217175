import { graphql } from '@api/gql/gql'

export const payOrder = graphql(`
    mutation payOrder($orderId: ID!) {
        pay_order(order_id: $orderId) {
            id
            total
            payment_url
        }
    }
`)
